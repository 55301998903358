import React from 'react';
import quantumLogo from '../../assets/LogoQTScaled.png';
import './footer.css';

const Footer = () => (
  <div className="quantum__footer section__padding">
{/*    <div className="quantum__footer-btn">
      <p><a href="/admission">Visit this page to Enroll</a></p>
    </div> */}

    <div className="quantum__footer-links">
      <div className="quantum__footer-links_logo">
        <img src={quantumLogo} alt="quantum_logo" />
        <p>University of Naples Federico II, <br /> All Rights Reserved</p>
      </div>
      <div className="quantum__footer-links_div">
        <h4>Links</h4>
        <p><a href='https://www.unina.it'>Federico II</a></p>
        <p><a href='https://www.facebook.com/unina.it'>Social Media</a></p>
        <p><a href='https://home.infn.it/it/'>INFN</a></p>
        <p><a href="http://www.qtlab.unina.it/index.php/contact-us/">Contacts</a></p>
      </div>
      <div className="quantum__footer-links_div">
        <h4>Quantum Computing</h4>
        <p><a href="http://www.phd-qt.unina.it/index.php/academic-board/">People</a></p>
        <p><a href="http://www.qtlab.unina.it/">Research</a></p>
        <p><a href="http://www.phd-qt.unina.it/">PhD</a></p>
        <p><a href="http://www.qtlab.unina.it/index.php/contact-us/">Contacts</a></p>
      </div>
      <div className="quantum__footer-links_div">
        <h4>Get in touch</h4>
        <p>Scientific Coordinator</p>
        <p>Coordinator: Prof. Francesco Tafuri</p>
        <p><a href="mailto:francesco.tafuri@unina.it">francesco.tafuri@unina.it</a></p>
        <p>Scientific Commission </p>
        <u> 
        <li> Angela Sara Cacciapuoti <a href="mailto:angelasara.cacciapuoti@unina.it">angelasara.cacciapuoti@unina.it  </a> </li>
        <li> Carlo Forestiere  <a href="mailto:carlo.forestiere@unina.it"> carlo.forestiere@unina.it  </a> </li>
        <li> Alioscia Hamma  <a href="mailto:alioscia.hamma@unina.it"> alioscia.hamma@unina.it  </a> </li>
        <li> Davide Massarotti  <a href="mailto:davide.massarotti@unina.it"> davide.massarotti@unina.it  </a> </li>
        <li> Giovanni Acampora  <a href="mailto:giovanni.acampora@unina.it"> giovanni.acampora@unina.it  </a> </li>
        </u>
      </div>
    </div>

    <div className="quantum__footer-copyright">
      <p>@2022 Quantum Computing and Engineering. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
