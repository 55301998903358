
import React from "react";
import './admission.css'
const Admission = () => {
  return (
    <div className='admission'>
      <div className='admission-title'>
        <h1>
          Admission
        </h1>
      </div>
      <div className='admission-text'>
        <p>
        For the admission to the Master Degree in QS&E it is necessary to have a bachelor’s 
        degree or an equivalent title, with a study plan where credits have been 
        achieved in the following disciplines:
        <ul>
          <li>24 CFU in Math (MAT/01-MAT/08)</li> 
          <li>12 CFU in Physics (FIS/01-FIS/08)</li>
          <li>6 CFU in Engineering INF/01, ING-INF/05</li>
          <li>12 additional CFU in the following SSD:
            <ul>
              <li>Physics: FIS/01-FIS/08</li>
              <li>Mathematical Physics: MAT/07</li>
              <li>Chemistry: CHIM/01-CHIM/02-CHIM/03</li>
              <li>Engineering: ING-IND/06; ING-IND/10; ING-IND/11; ING-IND/12; 
                  ING-IND/13; ING-IND/18; INGIND/19; ING-IND/20; ING-IND/22; 
                  ING-IND/31; ING-INF/01; ING-INF/02; ING-INF/06; I
                  NG-INF/07</li>
            </ul>  
          </li>
        </ul> 


        </p>

      </div>
      
    </div>
  );
};
  
export default Admission;