
import React from "react";
import './about.css';
const About = () => {
  return (
    <div className='about' id='about'>
      <div className='about-title'>
        <h1>
          About
        </h1>
      </div>
      <div className='about-text'>
        <p>
          Quantum technologies (QT) have radically innovative characteristics and have an 
          intersectorial impact, in strong discontinuity with the technologies available today. 
          The transformation that is envisaged is disruptive, QT having direct and far-reaching
          repercussions in many fields, from information technology to biology, 
          from telecommunications to engineering, from chemistry to pharmaceuticals, 
          from medicine to the environment. Some quantum solutions are already on the market. 
          At national level the great interest is testified by special measures dedicated to QT
          in the National Research Plan (PNR) 2021-2027 and in the Piano nazionale ripresa 
          resilienza (PNRR) under the framework of both the National Center C1 on High Performance
          Computing, with a special spoke dedicated to Quantum Computation, 
          and the Partnership PE4, entirely devoted to Quantum Technologies. 
          Building on continental scientific excellence, the European Commission launched 
          the 10-year Quantum Flagship program funded with 1 billion euros in 
          2018 for research and development projects under Horizon 2020. 
          The revolution promised by quantum technologies requires a wide scientific community 
          with multidisciplinary skills. Training will have to ensure the functioning of 
          the entire supply chain from basic research to the competitiveness of Italian high-tech 
          companies, with an enhancement of people with key skills, startups capable of 
          transferring and implementing new technologies and companies capable of integrating 
          QT into systems and services. The Master Degree in Quantum Science and Engineering (QS&E)
          aims at preparing this new generation of quantum scientists and engineers. 
          All courses will be hold in English and experimental courses will take place in top 
          class labs available at UNINA and in international companies collaborating with UNINA 
          and participating to the educational project. 
        </p>
      </div>  
    </div>
  );
};
  
export default About;
