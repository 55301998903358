import { useState, useEffect, useRef } from "react";
import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from "./Dropdown";


const MenuItems = ({ items }) => {
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();
    useEffect(() => {
        const handler = (event) => {
          if (dropdown && ref.current && !ref.current.contains(event.target)) {
            setDropdown(false);
          }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
          // Cleanup the event listener
          document.removeEventListener("mousedown", handler);
          document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);
    
    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };
    
    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };


    return (
    <li className="menu-items"
     ref={ref}
     onMouseEnter={onMouseEnter}
     onMouseLeave={onMouseLeave}>
      {items.submenu ? (
       <>
        <a
         type="button"
         aria-haspopup="menu"
         aria-expanded={dropdown ? "true" : "false"}
         onClick={() => setDropdown((prev) => !prev)}
        >
         {items.title}{" "}
        </a>
        <Dropdown 
         submenus={items.submenu}
         dropdown={dropdown} 
        />
       </>
      ) : (
        <p><a href={items.path}>
        {items.title}
        </a></p>
      )}
     </li>
    );
   }; 
   export default MenuItems;   