export const menuItems = [
    {
     title: "Home",
     path: "/"
    },
    {
     title: "About",
     path: "/about"
    },
    {
     title: "Academics",
     submenu: [
        {
            title: "Learning Plan",
            path: "/learning",
        },
        {
            title: "Seminars and Events",
            path: "https://www.qst.unina.it/?page_id=54"
        },
{/*        {
            title: "Faculty",
            path: "/faculty"
        },
         {
            title: "Documents and Forms",
            path: "/documents"
        } 
        {
            title: "UNINA",
            path: "http://www.unina.it/",
        }, */}

     ]
    },
    {
     title: "Admissions",
     submenu: [
        {
            title: "Enrolment",
            path: "/admission"
        },
        {
            title: "Info for Foreign Students",
            path: "http://www.international.unina.it/"
        },
     ]
    },
    {
     title: "Jobs",
     path: "/jobs",
    },
    {
     title: "Post Lauream ",
     path: "http://www.qtlab.unina.it/"
    },
{/*    {
     title: "News",
     path: "/news"
    }, */}
   ];
