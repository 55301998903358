import React from 'react';
import { Feature } from '../../components';
import './qt.css';
const Qt = () => {
  return (
    <div className="quantum__qt section__margin" id="qt">
      <div className="quantum__qt-feature">
          <Feature title="" 
          text="The Quantum Industrial Revolution is upon us. In the future, 
          quantum technologies and quantum in-formation science will revolutionize the industry, 
          the market, ways of working and creating, and will shape society. 
          The National Science agencies 
          around the world have committed a large amount of resources to the advancement of quantum technologies 
          and education, and Google, Amazon, IBM, Raytheon, all the largest hi-tech companies have their quantum 
          labs. A plethora of small enterprises and start-ups is flourishing trying to position themselves at the 
          forefront of the quantum frontier. 
          There is a very strong need of education in quantum science and technology for quantum workforce 
          development. The goal of the LM-QSE is twofold: first, to establish a program of training in quantum 
          information science for engineers, physicists, computer scientists, STEM students and workforce and 
          entrepreneurs interested in participating in the quantum technology revolution but who do not have a 
          working knowledge of quantum physics and quantum information;
          second, to have a strong societal impact. Revolutions in the structure of workforce, production, 
          industry and ways of life always present a double edge. On the one hand, they represent incredible 
          opportunities for economic growth. On the other hand, historically these opportunities are always seized 
          up but those who are already in an economically privileged position. By bringing quantum education to 
          the diverse community of students at the University Federico II of Napoli we want to push 
          the advancement of opportunities for students of both the area and international students. 
          We believe that by learning quantum physics, they will receive a great opportunity to make a step 
          forward in their life, learn the love for science, choose a quantum career, and be very 
          desirable for recruitment in both the workforce and the best research universities in the world."/>
        
      </div>
      <div className="quantum__qt-heading">
{/*        <h1 className="solid__text">Study with us</h1> */}
        <button type='button' 
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href="/admission";
      }}>
          Enroll
        </button> 
        
      </div>
      <div className='quantum__qt-container'>
        <Feature title="Learning Plan" text="Here you may find information about the learning plan, with an in-detail analysis of each year courses, and the list of courses that you
        may select to personalize your learning plan." link="/learning"/>
 {/*       <Feature title="Documents and Forms" text="Here you may find all documents and forms needed for the master degree." link="/documents"/> */}
        <Feature title="Seminars and Events" text="Here you may find information about all the events and seminars organized for the students of the MD" link="https://www.qst.unina.it/?page_id=54 "/>
      </div>
    </div>
  )
}

export default Qt
