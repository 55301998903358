import React from 'react';
import Navbar from './components/navbar/Navbar';
import { Footer} from './containers'
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import Home from './pages';
import About from './pages/about';
import Education from './pages/education';
import Admission from './pages/admission';
import Jobs from './pages/jobs';
import PostLauream from './pages/post_lauream';
import News from './pages/news';
import Unina from './pages/unina';
import Learning from './pages/learning';
import Seminars from './pages/seminars_events'
import Documents from './pages/documents';
import ForeignStudents from './pages/foreign_students.js';
import Working from './pages/working';
import Faculty from './pages/faculty';
import './App.css';  
function App() {
return (
  <div className="App">
    <Router>
    <div>
      <Navbar />
    </div>
    <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/about' element={<About/>} />
        <Route path='/education' element={<Education />} />
        <Route path='/admission' element={<Admission />} />
        <Route path='/jobs' element={<Jobs />} />
{/*        <Route path ='/working' element={<Working />} /> */}
        <Route path='/post_lauream' element={<PostLauream />} />
{/*        <Route path='/news' element={<News />} /> */}
        <Route path='/unina' element={<Unina />} />
        <Route path='/learning' element={<Learning />} />
        <Route path='/seminars_events' element={<Seminars />} /> 
{/*        <Route path='/documents' element={<Documents />} /> */}
        <Route path='/foreign_students' element={<ForeignStudents />} />
        <Route path='/faculty' element={<Faculty />} />
    </Routes>
    <Footer />
    </Router>
  </div>
);
}
  
export default App;
