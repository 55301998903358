import React from 'react';
import './feature.css';
const Feature = ( {title, text, link}) => {
  return (
    <div className="quantum__features-container__feature">
      <div className="quantum__features-container__feature-title">
        <div />
        <h1><a href={link}>{title}</a></h1>
      </div>
      <div className="quantum__features-container__feature-text">
        <p>{text}</p>
      </div>
    </div>
  )
}

export default Feature