import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import './learning.css'

const CourseTable = () => {

  const first_year_first_semester = [
    {
      name: "Foundations of Quantum Mechanics", cfus: "6", disciplines: "Math, Phys, CS", mod: "Mod I: Principles", 
      prerequisites: "",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Foundations of Quantum Mechanics", cfus: "6", disciplines: "Math, Phys, CS", mod: "Mod II: Physical systems", 
     prerequisites: "",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Microwave Circuits and Technologies", cfus: "6", disciplines: "Ing", mod: null, 
     prerequisites: "",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Digital Electronics for Quantum Applications", cfus: "6", disciplines: "Ing ", mod: null, 
     prerequisites: "",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Principles of Quantum Communication", cfus: "6", disciplines: "Affine", mod: null, 
     prerequisites: "",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
  ]
  const first_year_second_semester = [
    {
      name: "Quantum Computation", cfus: "6", disciplines: "Math, Phys, CS", mod: "Mod I: Theory", 
     prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Quantum Computation", cfus: "6", disciplines: "Math, Phys, CS", mod: "Mod II: Architectures and High Performance", 
     prerequisites: "QC Mod I",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Applied Quantum Systems", cfus: "9", disciplines: "Ing ", mod: null, 
      prerequisites: "FQM, MCT, DEQA",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Quantum circuit electrodynamics and Quantum devices", cfus: "9", disciplines: ["Ing "], mod: null, 
      prerequisites: "FQM, MCT, DEQA",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
  ]

  const second_year_first_semester = [
    {
      name: "Course selected from Table A or Article 1.", cfus: "18", disciplines: "Affine", mod: null, 
      prerequisites:"",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Course selected from Article 2.", cfus: "12", disciplines: "Affine", mod: null, 
      prerequisites:"",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
  ]

  const second_year_second_semester = [
    {
      name: "Course selected from Article 2.", cfus: "6", disciplines: "Affine", mod: null, 
      prerequisites:"",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Course selected from Article 3.", cfus: "3", disciplines: ["Ing "], mod: null, 
      prerequisites:"",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Final Examination", cfus: "21", disciplines:"", mod: null, 
      prof:"", prerequisites:"",
      link:""},
  ]

  const renderCourse = (course, index, mod) => {
    let to_return;
    if (mod != null) {
      to_return = ( 
      <tr key={index}>
        <td>{<a href={course.link}>{course.name}</a>}</td>
        <td>{course.mod}</td>
        <td>{course.cfus}</td>
        <td>{course.disciplines}</td>
        <td>{course.prerequisites}</td>
      </tr>);
    }
    else {
      to_return = (
        <tr key={index}>
        <td colSpan={2}>{<a href={course.link}>{course.name}</a>}</td>
        <td>{course.cfus}</td>
        <td>{course.disciplines}</td>
        <td>{course.prerequisites}</td>
      </tr>);
    }
    return to_return
   }

  return (
    <ReactBootstrap.Table bordered striped responsive hover>
    <thead>
        <tr>
          <th>Name</th>
          <th>Module</th>
          <th>CFUs</th>
          <th>Disciplines</th>
          <td>Prerequisites</td>
        </tr>
      <tr>
        <th colSpan={6}> First Year - Fist Semester</th>
      </tr>
    </thead>
    <tbody>
      {first_year_first_semester.map(renderCourse)}
    </tbody>
    <thead>
      <tr>
        <th colSpan={6}> First Year - Second Semester</th>
      </tr>
    </thead>
    <tbody>
      {first_year_second_semester.map(renderCourse)}
    </tbody>
    <thead>
      <tr>
        <th colSpan={6}> Second Year - First Semester</th>
      </tr>
    </thead>
    <tbody>
      {second_year_first_semester.map(renderCourse)}
    </tbody>
    <thead>
      <tr>
        <th colSpan={6}> Second Year - Second Semester</th>
      </tr>
    </thead>
    <tbody>
      {second_year_second_semester.map(renderCourse)}
    </tbody>
    
  </ReactBootstrap.Table>
  )
}

const TableA = () => {
  const courses = [
    {
      name: "Physics of Quantum Information", cfus: "6",
      prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Quantum Optics", cfus: "6",
      prof: 'Prof. Francesco Tafuri', prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
     {
      name: "Quantum Simulators", cfus: "6",
      prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Quantum Materials and Solid-State Qubits", cfus: "6",
      prof: 'Prof. Francesco Tafuri', prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Advanced Computer Programming", cfus: "6",
      prerequisites: "",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Quantum Software", cfus: "6",
      prerequisites: "QC",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
     {
      name: "Quantum Metrology and Sensors", cfus: "6",
      prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Advanced Quantum Communication Networks", cfus: "6",
      prerequisites: "PQC",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Quantum Detectors for Fundamental Science", cfus: "6",
      prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Superconducting Quantum Technologies", cfus: "6",
      prerequisites: "AQS",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
     {
      name: "Quantum Chemistry", cfus: "6",
      prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Nanoscale Processing and Characterization for Advanced Devices", cfus: "6",
      prerequisites: "",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Nonlinear Systems", cfus: "6",
      prerequisites: "",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Quantum Measurement Theory", cfus: "6",
      prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
     {
      name: "Quantum Algorithms", cfus: "6",
      prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Quantum Detectors for Applied Science", cfus: "6",
      prerequisites: "",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Mathematics and Quantum Mechanics", cfus: "6",
      prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},
    {
      name: "Mathematical Methods for Quantum Information", cfus: "6",
      prerequisites: "FQM",
      link:"https://www.docenti.unina.it/#!/professor/4652414e434553434f544146555249544652464e4336345231334638333948/riferimenti"},          
  ]

  const renderCourse = (course, index) => {
    return (
        <tr key={index}>
        <td>{<a href={course.link}>{course.name}</a>}</td>
        <td>{course.cfus}</td>
        <td>{course.prerequisites}</td>
      </tr>);
    }
  return (
    <ReactBootstrap.Table bordered striped responsive hover>
    <thead>
        <tr>
          <th>Name</th>
          <th>CFUs</th>
          <td>Prerequisites</td>
        </tr>
    </thead>
    <tbody>
      {courses.map(renderCourse)}
    </tbody>
    
  </ReactBootstrap.Table>
  )
}


const Learning = () => {
  return (
    <div className='learning'>
      <div className='learning-title'>
        <h1>
          Learning Plans and Outcomes
        </h1>
      </div>
      <div className='learning-text'>
        <p>
          The Master Degree in Quantum Science and Engineering promotes a graduate curriculum that bridges between 
          multiple disciplines in order to train a new generation of quantum scientists and engineers. Hereafter you 
          may find the learninng plan with all the mandatory courses. 
          {/* By clicking on a Course or Professor, you will be
          redirected to the relative course page where you may find additional information about the lectures, dates,
          exams and so on.  
          Also make sure to check Table A, and Articles 1, 2, and 3 to see the full list of courses which you may select from a wide range of courses offered
          by several departments and master degrees of the Federico II.   */}
        </p>
      </div>
      <div className="table">
        <h2>
          Learning Plan
        </h2>
        <CourseTable/>
        <h2>
          Table A
        </h2>
        <TableA/>
      </div>
      <div className='learning-text'>
        <p>
          The student may also include in this slot <span style={{fontWeight: 'bold'}}> any course </span> from the following
          Master Degrees of the Federico II: Physics, Computer Science, Electrical Engineering, 
          Telecomunication Engineering and Information Technology 
        </p>
        <p>
          The student may also include any course available at Federico II or internship, upon aproval from the board
        </p>
        <p>
          The student may also make use of the CFUs to improve his/her linguistic or informatic skils, attend internships or any learning experience
          needed for the completion of the final examination and Thesis such as: study a software, programming language, a language and so on. 
        </p>
      </div>
    
    </div>
  );
};
  
export default Learning;



