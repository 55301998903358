import React from "react";
  
const Faculty = () => {
  return (
    <div>
      <h1>
        Faculty
      </h1>
    </div>
  );
};
  
export default Faculty;