
import React from "react";
  
const Education = () => {
  return (
    <div>
      <h1>
        Education
      </h1>
    </div>
  );
};
  
export default Education;