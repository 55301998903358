
import React from "react";
  
const Seminars = () => {
  return (
    <div>
      <h1>
        Seminars and Events
      </h1>
    </div>
  );
};
  
export default Seminars;