
import React from "react";
  
const Jobs = () => {
  return (
    <div className='jobs'>
      <div className='jobs-title'>
        <h1>
          Jobs
        </h1>
      </div>
      <div className='jobs-text'>
        <p> The Master Degree in QS&E offers very relevant job opportunities as experts in quantum technologies for cutting-edge research activities in high-Tech industries, research centers and Universities. Graduate students in QS&E are entitled: i) to develop applications, systems and services based on quantum technologies aimed at quantum information and computation, quantum communication, quantum simulation, quantum sensors and metrology; ii) to develop quantum software; iii) to carry out advanced research in the field of quantum science and engineering, tackling fascinating problems in every area. 
        </p>
      </div>
    </div>

  );
};
  
export default Jobs;
