
import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import { Link, NavLink } from 'react-router-dom';
import './navbar.css';
import {menuItems} from '../menuitem/menu'
import MenuItems from "../menuitem/MenuItems";
import logo from '../../assets/LogoQTScaled.png';


  
const Menu = () => {
  return (
    <>
    <ul className="menus">
    {menuItems.map((menu, index) => {
     return <MenuItems items={menu} key={index} />;
    })}
   </ul>
    </>
  )
}
  

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="quantum__navbar section__margin">
      <div className="quantum__navbar-links">
        <div className="quantum__navbar-links_logo">
          <img src={logo} />
        </div>
        <div className="quantum__navbar-links_container">    
         <Menu className='menu'/>
        </div>
      </div>
      <div className='quantum__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color="black" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="black" size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className="quantum__navbar-menu_container scale-up-center">
            <div className="quantum__navbar-menu_container-links">
              <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
  
export default Navbar;