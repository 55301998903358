import React from 'react';
import './header.css';
import hi_01 from '../../assets/Home_Image_1.jpg';
import hi_02 from '../../assets/Home_Image_2.jpg';
import hi_03 from '../../assets/Home_Image_3.jpeg';
import hi_04 from '../../assets/Home_Image_4.png';
import SimpleImageSlider from "react-simple-image-slider";

            
const images = [
  hi_01,
  hi_02,
  hi_03,
  hi_04
]

const ImageSliderStyle = { width: '60vw', height: '60vh'}


const Header = () => {
  
  return (
    <div className='quantum__header section__padding' id="home">
      <div className='quantum__header-img'>
      <div>
      <SimpleImageSlider
        width={ImageSliderStyle.width}
        height={ImageSliderStyle.height}
        images={images}
        showBullets={true}
        showNavs={true}
        autoPlay={true}
        autoPlayDelay={5.0}
      />
      </div>
      </div>
    </div>
  )
}

export default Header