
import React from 'react';
import '../App.css'
import { Qt, Header } from '../containers'
const Home = () => {
  return (
    <div className="App">
      <Header />
      <Qt />
    </div>
    
  );
};
  
export default Home;